<template>
	<TheHeader>
		<template v-slot:title>
			Resources
		</template>
		<template v-slot:description>
			Useful resources which help you with Optiomat.
		</template>
	</TheHeader>

	<section class="page-container xl:max-w-screen-lg max-w-screen-md mt-10">
		<div class="md:flex items-start md:space-x-12 pt-9">
			<div :class="{'mb-0': i+1 === guides.length, 'mb-6': i+1 !== guides.length}" class="md:w-4/12 md:mb-0" v-for="(guide, i) in guides">

				<a v-if="guide.link !== '#'" :href="guide.link" target="_blank" class="border-2 border-secondary rounded-xl text-center py-8 px-6 block transform hover:scale-105 hover:text-white hover:bg-theme-dark transition-all hover:shadow-xl">
					<img class="mx-auto mb-8" src="/images/download-document-icon.svg" :alt="guide.title">
					<b class="font-extrabold" v-html="guide.title"></b>
				</a>

				<div v-if="guide.link === '#'" @click="openContactFormPopup" class="cursor-pointer border-2 border-secondary rounded-xl text-center py-8 px-6 block transform hover:scale-105 hover:text-white hover:bg-theme-dark transition-all hover:shadow-xl">
					<img class="mx-auto mb-8" src="/images/download-document-icon.svg" :alt="guide.title">
					<b class="font-extrabold" v-html="guide.title"></b>
				</div>
			</div>
		</div>
	</section>

</template>

<script>
	import TheHeader from "../components/TheHeader";

	export default {
		name: 'UserGuides',
		components: {
			TheHeader,
		},
		data() {
			return {
				guides: [
					{
						title: 'User Guide For<br/><span class="text-theme">Company Founders</span>',
						link: '/documents/optiomat-user-guide-for-company-founders.pdf',
					},
					{
						title: 'User Guide For<br/><span class="text-theme">Employees</span>',
						link: '/documents/optiomat-user-guide-for-employees-v1.pdf',
					},
					{
						title: 'User Guide For<br/><span class="text-theme">Trustees</span>',
						link: '#',
					},
					{
						title: 'ESOP<br/><span class="text-theme">Basics</span>',
						link: '/documents/esop-for-founders-optiomat.pdf',
					},
				]
			}
		},
		methods: {
			openContactFormPopup() {
				if (window.innerWidth < 640) {
					let el = document.getElementById('contact-us')

					this.$store.commit('TOGGLE_CONTACT_FORM', true)

					window.scrollTo({
						top: el.getBoundingClientRect().top + window.scrollY,
						behavior: 'smooth',
					})

				} else {
					this.$store.commit('TOGGLE_CONTACT_POPUP', true)
				}
			}
		}
	}
</script>
